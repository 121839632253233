<template>
    <div class="bg">
        <el-form label-width="80px" :model="info">
            <el-form-item label="贴子类型">
                <el-radio v-model="navIndex" :label="i" :key="i" v-for="(m, i) in navList">{{m.name}}</el-radio>
            </el-form-item>
            <div v-if="navIndex!=0">
                <el-form-item label="标题">
                    <el-input :rows="5" type="textarea" v-model="info.title" placeholder="填写标题更吸引人哦" maxlength="31" show-word-limit :clearable=true></el-input>
                </el-form-item>
                <el-form-item label="补充描述">
                    <el-input :rows="8" type="textarea" v-model="info.content" placeholder="填写正文" maxlength="500" show-word-limit :clearable=true></el-input>
                </el-form-item>
                <el-form-item label="#话题">
                    <el-tag class="tag" :key="i" v-for="(t, i) in tags" closable :disable-transitions="false" @close="rmTag(i)">#{{t}}</el-tag>
                    <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @input="getTags()" @blur="handleInputConfirm"></el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput()">+ 话题</el-button>
                    <div class="tag-box" v-show="tagList.length">推荐：
                        <el-tag class="t" :key="i" v-for="(m, i) in tagList" type="info" size="small" @click="addTag(m)">#{{m}}</el-tag>
                    </div>
                </el-form-item>
                <el-form-item label="&经验">
                    <el-input style="max-width: 150px;display: block;" size="small" placeholder="搜索经验" v-model="rzkw" clearable></el-input>
                    <el-tag class="tag" :key="i" v-for="(t, i) in rz" closable :disable-transitions="false" @close="rmTag(i, 1)">&{{t}}</el-tag>
                    <div class="tag-box" v-show="rzList.length">
                        <el-tag v-if="m.name.indexOf(rzkw)!=-1" class="t" :key="i" v-for="(m, i) in rzList" type="info" size="small" @click="addTag(m.name, 1)">&{{m.name}}</el-tag>
                    </div>
                </el-form-item>
                <el-form-item label="链接">
                    <el-input type="text" v-model="info.linkurl" placeholder="请填写链接"></el-input>
                </el-form-item>
                <el-form-item label="谁可以看">
                    <el-radio v-model="info.private" label="0">公开</el-radio>
                    <el-radio v-model="info.private" label="1">私密(仅自己可见)</el-radio>
                </el-form-item>
                <el-form-item label="合编">
                    <el-radio v-model="info.hebian" label="0">禁止</el-radio>
                    <el-radio v-model="info.hebian" label="1">允许</el-radio>
                </el-form-item>
                <el-form-item label="合编用户" v-show="info.hebian==1">
                    <el-input class="input-new-tag" placeholder="按回车搜索" v-model="hbkw" size="small" @keyup.enter.native="getMyGz()"></el-input>
                    <ul class="mix-user-ul " v-show="hbList.length">
                        <li class="list" v-if="!hbUser[m.user_id]" v-for="(m, mi) in hbList" @click.stop="setHbUser(m, 1)">
                            <img class="tx" :src="rImg(m.user_head)" :onerror="txImg" alt="">
                            <span class="name">{{m.remark_name || m.user_name}}</span>
                            <i class="el-icon-circle-plus"></i>
                        </li>
                    </ul>
                    <ul class="mix-user-ul ready-user" v-show="Object.keys(hbUser).length">
                        <li class="list" v-for="(m, k ,i) in hbUser" @click.stop="setHbUser(m)">
                            <img class="tx" :src="rImg(m.user_head)" :onerror="txImg" alt="">
                            <span class="name">{{m.remark_name || m.user_name}}</span>
                            <i class="el-icon-remove"></i>
                        </li>
                    </ul>
                </el-form-item>
            </div>
            <div v-else>
                <el-form-item label="内容">
                    <el-input :rows="8" type="textarea" v-model="info.content" placeholder="记录你美丽的点点滴滴（不多于500个字）" maxlength="500" show-word-limit :clearable=true></el-input>
                </el-form-item>
            </div>
            <!-- <el-form-item label="资源类型">
                <el-radio v-model="media" :label="i" :key="i" v-for="(m,i) in mediaArr">{{m}}</el-radio>
            </el-form-item> -->
            <el-form-item v-if="navIndex!=2" label="选择图片">
                <div class="img-box">
                    <div class="img" :key="i" v-for="(m, i) in imgList">
                        <img :src="rImg(m.url)" />
                        <i class="el-icon-error" @click.stop="rmImg(i)"></i>
                    </div>
                    <div class="add img" @click="selectFile()">
                        <i class="el-icon-plus"></i>
                    </div>
                </div>
            </el-form-item>
            <el-form-item v-else label="选择视频">
                <div class="img-box">
                    <div class="img video" v-show="video.url">
                        <video ref="videoUrl" :src="video.url" :poster="video.cover"></video>
                        <i class="el-icon-error" @click.stop="rmVideo()"></i>
                        <span class="cover" @click.stop="setCover()">修改封面</span>
                    </div>
                    <div class="add img" v-show="!video.url" @click="selectFile(1)">
                        <i class="el-icon-plus"></i>
                    </div>
                </div>
            </el-form-item>
            <!-- <el-form-item label="分类" v-if="navList[navIndex] && navList[navIndex].auth">
        <el-select :value="label.name" :clearable=true placeholder="选择分类" @change="setLabels" no-data-text="请在APP身份认证后再尝试">
          <el-option v-for="item in labels" :key="item.id" :label="item.title" :value="item"></el-option>
        </el-select>
      </el-form-item> -->
            <el-form-item label="观看价格" v-if="navIndex!=0 && rz.length">
                <el-input type="text" v-model="info.price" placeholder="价格范围0~0.1元"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="submit()">立即发布</el-button>
                <!-- <el-button>取消</el-button> -->
            </el-form-item>
        </el-form>
        <canvas ref="canvas" id="my-canvas" style="visibility:hidden;"></canvas>
        <input type="file" multiple="true" @change="getImgs" ref="img" style="display: none" accept="image/*" />
        <input type="file" @change="getCover" ref="cover" style="display: none" accept="image/*" />
        <input type="file" @change="getVideo" ref="video" style="display: none" accept=".mp4" />
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {},
        data() {
            return {
                video: {
                    url: "",
                    file: "",
                    cover: "",
                    cover_file: ''
                },
                imgList: [],
                label: {
                    id: "",
                    name: ""
                },
                info: {
                    title: "",
                    content: "",
                    price: "",
                    private: "0",
                    linkurl: '',
                    hebian: "0"
                },
                navList: [{
                    name: '动态'
                }, {
                    name: '图文'
                }, {
                    name: '视频'
                }],
                navIndex: 0,
                labels: [],
                media: 0, // 0图片 1视频
                mediaArr: ["图片", "视频"],
                tags: [],
                rz: [], //我认证的标签列表
                rzList: [], //认证列表
                rzkw: '',
                inputVisible: false,
                inputValue: "",
                tagList: [],
                phInfo: {
                    0: {

                    }
                },
                hbkw: '',
                hbList: [],
                hbUser: {}
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            // this.getPub();
            // this.getMyRz();
            this.getTags();
            this.getMyLabel()
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            setHbUser(m, t) {
                if (t) {
                    this.$set(this.hbUser, m.user_id, m);
                } else {
                    this.$delete(this.hbUser, m.user_id, 1);
                }
            },
            // ...mapMutations([])
            // 获取已关注用户
            async getMyGz() {
                let param = {
                    user_id: this.$y_getKey('userid'),
                    types: 1,
                    page: 1,
                    pagesize: 10
                }
                if (this.hbkw) {
                    param.keyword = this.hbkw
                }
                let {
                    code,
                    result
                } = await this.$y_ajax("home/Dymanic/myattention", param);
                if (code == 200) {
                    this.hbList = result;
                }
            },
            async getTags() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/nihao/topic", {
                    name: this.inputValue,
                });
                if (code == 200) {
                    this.tagList = data;
                }
            },
            // 移除标签
            rmTag(i, rz) {
                if (rz) {
                    this.rz.splice(i, 1);
                } else {
                    this.tags.splice(i, 1);
                }
            },
            showInput(rz) {
                this.inputVisible = true;
                this.$nextTick((_) => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            addTag(m, rz) {
                if (rz) {
                    this.rz.push(m);
                } else {
                    this.tags.push(m);
                }
            },
            handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.tags.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = "";
            },
            async getMyLabel() {
                let {
                    code,
                    msg,
                    data
                } = await this.$y_ajax('api/Nihao/userLabel', {
                    user_id: this.$y_getKey('userid')
                })
                if (code == 200) {
                    this.rzList = data.jingli.concat(data.shanchang)
                } else {
                    this.$y_msg(msg)
                }
            },
            // 获取发布规则
            async getPub() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/nihao/prepublish");
                if (code == 200) {
                    let arr = [];
                    for (let k in data) {
                        let o = data[k];
                        o.cls = k;
                        arr.push(o);
                    }
                    this.navList = arr;
                }
            },
            // 获取我认证的类别
            async getMyRz() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/nihao/category", {
                    auth: 1,
                });
                if (code == 200) {
                    if (data[0].id == 0) {
                        this.labels = data[0].children;
                    }
                }
            },
            // 选择分类
            setLabels(m) {
                this.label = {
                    name: m.title,
                    id: m.id,
                };
            },
            // 确定发布
            async submit() {
                if (!this.info.content) {
                    this.$y_msg("请输入内容");
                    return;
                }
                let obj = {

                };
                if (this.navIndex != 0) {
                    // if (!this.info.title) {
                    //     this.$y_msg("请输入标题");
                    //     return;
                    // }
                    // if (!this.tags.length) {
                    //     this.$y_msg("请添加标签");
                    //     return;
                    // }

                    if (this.navIndex == 1 && !this.imgList.length) {
                        this.$y_msg("请选择图片");
                        return;
                    }
                    if (this.navIndex == 2 && !this.video.url) {
                        this.$y_msg("请选择视频");
                        return;
                    }

                    let tags = {};
                    this.tags.forEach((v) => {
                        tags[v] = 1;
                    });
                    let tagStr = "";
                    for (let k in tags) {
                        tagStr += "#" + k;
                    }

                    let rz = {};
                    this.rz.forEach(v => {
                        rz[v] = 1;
                    })
                    let rzStr = "";
                    for (let k in rz) {
                        rzStr += "#" + k;
                    }

                    obj.title = this.info.title;
                    obj.category_id = 0;
                    obj.topic = tagStr;
                    obj.topic_experience = rzStr;
                    obj.private = this.info.private;
                    if (this.info.linkurl) {
                        obj.link = this.info.linkurl;
                    }
                    if (this.info.hebian == 1) {
                        obj.hebian = 1;
                        if (Object.keys(this.hbUser).length) {
                            let u_arr = [];
                            for (let k in this.hbUser) {
                                u_arr.push(k);
                            }
                            obj.hebian_can_uids = u_arr.join(',');
                        }
                    } else {
                        obj.hebian = 0;
                    }

                    let price = Number(this.info.price);
                    if (price) {
                        if (isNaN(price)) {
                            this.$y_msg("价格有误");
                            return;
                        }
                        obj.price = price;
                    }
                } else {
                    obj.userid = this.$y_getKey('userid');
                    obj.type = 0;
                }
                obj.content = this.info.content;

                this.$y_loading();
                if (this.navIndex != 2) {
                    // 上传图片
                    let arr = [];
                    this.imgList.forEach((v) => {
                        arr.push(v.file);
                    });
                    if (arr.length) {
                        obj.masterimg = await this.$y_upImgs(arr);
                        if (!obj.masterimg) {
                            this.$y_loadingClose();
                            return;
                        }
                    }
                    this.submitDy(obj);
                } else {
                    obj.video = await this.$y_upVideo(this.video.file);
                    if (obj.video) {
                        obj.cover = await this.$y_upImg(this.video.cover_file);
                        if (obj.cover) {
                            this.submitDy(obj);
                        } else {
                            this.$y_loadingClose();
                        }
                    } else {
                        this.$y_loadingClose();
                    }
                }
                //
            },
            async submitDy(obj) {
                let uu = this.navIndex == 0 ? 'home/Dymanic/adddymanic' : 'api/Experience/publish';
                let {
                    code,
                    msg
                } = await this.$y_ajax(uu, obj);
                this.$y_loadingClose();
                this.$y_msg(msg);
                if (code == 200) {
                    this.imgList = [];
                    this.video = {
                        url: "",
                        file: "",
                        cover: "",
                    };
                    this.info = {
                        title: "",
                        content: "",
                        price: "",
                    };
                    this.tags = [];
                }
            },
            // 修改封面
            setCover() {
                this.$refs.cover.click();
            },
            // 选择文件
            selectFile(type) {
                if (type) {
                    this.$refs.video.click();
                } else {
                    this.$refs.img.click();
                }
            },
            getCover() {
                let files = this.$refs.cover.files;
                this.video.cover = window.URL.createObjectURL(files[0]);
                this.video.cover_file = files[0];
            },
            // 转换图片
            getImgs() {
                let files = this.$refs.img.files;
                for (let i = 0, len = files.length; i < len; i++) {
                    let u = window.URL.createObjectURL(files[i]);
                    this.imgList.push({
                        url: u,
                        file: files[i],
                    });
                }
            },
            // 移除图片
            rmImg(i) {
                this.imgList.splice(i, 1);
            },
            // 转换视频
            getVideo() {
                let _this = this;
                let $video = this.$refs.video;
                let files = this.$refs.video.files;
                this.video.url = window.URL.createObjectURL(files[0]);
                this.video.file = files[0];
                setTimeout(function() {
                    let vv = _this.$refs.videoUrl;
                    let w = vv.offsetWidth / (vv.offsetHeight / 100);
                    let canvas = _this.$refs.canvas;
                    canvas.width = w;
                    canvas.height = 100;
                    canvas.getContext("2d").drawImage(vv, 0, 0, w, 100);
                    let dataURL = canvas.toDataURL("image/jpg");
                    _this.video.cover_file = _this.base64toFile(dataURL);
                }, 1000);
                // vv.addEventListener("canplay", function () {});
            },
            // 移除视频
            rmVideo() {
                this.video = {
                    url: "",
                    file: "",
                    cover: "",
                    cover_file: ''
                };
            },
            // base64转文件
            base64toFile(dataurl) {
                let filename = new Date().getTime();
                let arr = dataurl.split(",");
                let mime = arr[0].match(/:(.*?);/)[1];
                // suffix是该文件的后缀
                let suffix = mime.split("/")[1];
                // atob 对经过 base-64 编码的字符串进行解码
                let bstr = atob(arr[1]);
                // n 是解码后的长度
                let n = bstr.length;
                // Uint8Array 数组类型表示一个 8 位无符号整型数组 初始值都是 数子0
                let u8arr = new Uint8Array(n);
                // charCodeAt() 方法可返回指定位置的字符的 Unicode 编码。这个返回值是 0 - 65535 之间的整数
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                // new File返回File对象 第一个参数是 ArraryBuffer 或 Bolb 或Arrary 第二个参数是文件名
                // 第三个参数是 要放到文件中的内容的 MIME 类型
                return new File([u8arr], `${filename}.${suffix}`, {
                    type: mime,
                });
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        },
    };
</script>

<style lang="less" scoped>
    .bg {
        background: #fff;
        padding: 10px;
    }

    .tag {
        margin-right: 10px;
    }

    .input-new-tag {
        width: auto;
    }

    .tag-box {
        display: -webkit-box;
        overflow-x: auto;
        // width: 50vw;
        width: 100%;
        white-space: nowrap;

        &::-webkit-scrollbar {
            height: 5px;
            background: #f3f3f3;
        }

        &::-webkit-scrollbar-thumb {
            background: #aaa;
            border-radius: 7px;
        }

        .t {
            border-radius: 20px;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    /deep/.tip-box {
        li {
            line-height: 1 !important;
            border-bottom: 1px solid #f3f3f3;
            padding-top: 7px;
            padding-bottom: 7px;

            &:last-child {
                border-bottom: 0;
            }

            .des {
                margin-top: 5px;
                color: #aaa;
                font-size: 12px;
            }
        }
    }

    .img-box {
        display: flex;
        flex-wrap: wrap;

        .img {
            width: 100px;
            height: 100px;
            border-radius: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            box-sizing: border-box;
            overflow: hidden;
            object-fit: cover;
            cursor: pointer;
            position: relative;

            &.add {
                border: 1px dashed #aaa;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .el-icon-error {
                position: absolute;
                top: 5px;
                right: 5px;
                color: #aaa;
                font-size: 20px;
            }

            &.video {
                width: auto;
                position: relative;

                video {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }

                .cover {
                    background: rgba(0, 0, 0, 0.5);
                    text-align: center;
                    color: #ccc;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    font-size: 14px;
                    width: 100%;
                }
            }
        }
    }

    /* 合编 */
    .mix-user-ul {
        max-height: 200px;
        overflow-y: auto;

        .list {
            margin-right: 10px;
            margin-bottom: 10px;
            display: inline-flex;
            align-items: center;
            padding: 10px;
            cursor: pointer;

            .name {
                margin: 0 10px;

            }

            i {
                font-size: 25px;
            }

            .el-icon-circle-plus {
                color: #FD5621;
            }

            .el-icon-remove {
                color: #aaa;
            }
        }
    }

    .ready-user {
        padding-top: 10px;
        background: #fafafa;
    }
</style>